@import './_mixins.scss';

.ant-list {
    &.Appointments-List {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0!important;
    }
}

.Appointment-Info-Container {
    padding: 0 !important;
}

.Appointment-Card {
    border-radius: 15px!important;
    margin-bottom: 20px!important;
    
    .ant-card-body {
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
        border-radius: 20px;

        @include xs {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .ant-card-actions {
        li {
            color: #2A9CFF !important;

            & span a:not(.ant-btn),
            .ant-card-actions > li > span > .anticon {
                color: #2A9CFF;
            }
        }   
    }

    ul.ant-card-actions {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.Appointment-Booking-Title {
    color: $primaryColor !important;
    font-weight: 700!important;
    font-size: 2.2rem;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    margin-left: 100px;
}

.Attachment-Card-Title {
    color: $primaryColor !important;
    font-weight: 800!important;
    width: 100%;
}

.Appointment-Detail-Title {
    color: $primaryColor !important;
    font-weight: 400;
}

.Appointment-Detail-Text {
    white-space: nowrap;
    color: $secondaryLightTextColor !important;
    font-weight: 700;
    font-size: 1.9rem !important;
    @include sm {
        font-size: 1.6rem !important; 
    }
    @include xs {
        font-size: 1.5rem !important; 
    }
    text-align: center;
}

.Appointment-Booking-Details {
    color: #193B68!important;
    font-size: 1.6rem;
    margin-left: 9.75rem;

    &.Location {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: unset!important;
    margin-bottom: 15px;
}

.ant-badge-count-sm {
    height: 22px!important;
    font-size: 17px!important;
    line-height: 23px!important;
    border-radius: 21px!important;
}

.Card-Gradient-Primary {
    background: rgb(4,70,143) !important;
    box-shadow: 0px 10px 10px 0px rgba(1, 111, 254, 0.2);
    border-radius: 20px!important;
    min-height: 170px;
    display: flex;
    align-items: center;
    text-align: center;

    @include xs {
        margin-bottom: 10px!important;
    }

    @include sm {
        margin-bottom: 10px!important;
    }

    .ant-card-body {
        width: 100%;
    }

    h4 {
        color: #fff!important;
    }
}

.Contact-Cancellation {
    margin-bottom: 15px;

    @include xs {
        text-align: left;
    }

    @include md {
        text-align: right;
    }

    h3 {
        @include xs {
            font-size: 1.7rem;
        }

        @include md {
            font-size: 1.4rem;
        }
    }
}

.Floating-Call-Button {
    border-radius: 50%;
    background: #35C235!important;
    border: none!important;
    box-shadow: 0 0 0 0 rgba(53, 194, 53, 0.7);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;  
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    transition: all 300ms ease-in-out;

    &:hover {
        -webkit-animation: none!important;
    }
}

.Modal-Message-Details {
    .ant-modal-body {
        padding: 0!important;
    }
}

.Appointment-Card-Sub-Message {
    font-size: 14px;
    line-height: 1.5;
    color: #6c757d;
}

.text-info{
    font-size: 14px;
}
/* Animation */

@-webkit-keyframes pulsing {
  to {
    
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.1);
  }
}

.Card-Gradient {
    background-color: #f2f2f2 !important;
    border-radius: 7px !important;
    box-shadow: 0px 10px 10px 0px rgba(198, 198, 198, 0.2) !important;
}

.Disable-ActionLayout {
    pointer-events: none;
    opacity: 0.4;
}

.Appointment-Detail-Info {    
    @media (max-width: 576px) {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

@media (max-width: 576px) {
    .ant-list-item{
        position: relative;
    }
    .Message-Tag {
        margin-top: 0;
        margin-bottom: 7px;
    }
    .ant-list .ant-btn{
        width: auto!important;
        bottom: 15px;
    }    
}
.Link-Data {
    font-weight: 600!important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        display: inline !important;
        text-decoration: underline;
    }

    @media (min-width: 768px) {
        &.ant-btn-link, .ant-btn {
            min-width: 80px;
            min-height: 30px !important;

            span {
                font-size: 1.5rem;
            }
        }
    }

    @media (max-width: 576px) {
        &.ant-btn-link, .ant-btn {
            width: 100%;
            min-height: 30px !important;

            span {
                font-size: 1.5rem;
            }
        }
    }

    &.ant-btn {
        line-height: 1.4rem !important;
        padding: 0 0.5rem !important;
        height: 22px !important;
    }
}

.Link-Data.ant-btn{
    margin: -8px 0;
}

@media (max-width: 374px) {
    .fivef-navbar .fivef-navbar-brand{
        width: 170px!important;
    }
    .ant-card-actions > li > span a:not(.ant-btn), .ant-card-actions > li > span > .anticon, .ant-card-actions > li > span{
        font-size: 10px!important;
    }
    .ant-btn > span{
        font-size: 12px!important;
    }
    .ant-btn.ant-btn-primary > span{
        font-size: 8px!important;
        display: block;
    }
    .Appointment-Info-Container .ant-card-body{
        font-size: 10px;
    }
}

.FormModalButton {
    &.ant-btn {
        padding: 4px 8px !important;
    }

    @media (max-width: 576px) {
        &.ant-btn {
            min-height: 50px !important;
        }
    }
}

.FormModalCol {
    &.col-5,.col-lg-8,.col-md-6,.col-xl-10 {
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    &.col-3,.col-lg-2,.col-md-3,.col-xl-13 {
        padding-right: 5px !important;
        padding-left: 8px !important;
    }

    &.col-4,.col-lg-2,.col-md-3,.col-xl-1 {
        padding-right: 8px !important;
        padding-left: 5px !important;
    }
}

.MessageList-Item {
    @media (max-width: 374px) {
        .ant-list-item-action{
            &.li{
                font-size: 11px !important;
            }
        }
    }
}
