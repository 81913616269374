@import 'mixins';
@import 'variables';

.Settings-Dialog {

  .Settings-Wrapper {
    background-color: #1890ff;

    @include sm {
      background-color: transparent;
    }
  }

  .MuiPaper-root {
    border: none;
    @include sm {
      //background-color: transparent;
    }
  }

  .Settings-UserProfile {
    width: auto;
    height: 7rem;
  }

  .Settings-Title {
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 999;
  }

  .Settings-Content {
    margin-top: 6.4rem;
    padding-top: 6.5rem !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include sm {
      position: inherit;
      padding-top: 6.5rem !important;
      padding-bottom: 3rem;
      border-radius: 4px;
    }
  }
}