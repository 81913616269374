@import 'mixins.scss';
@import 'variables.scss';

.PoweredBy {
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 12px;
  bottom: 12px;
  margin: auto;
}